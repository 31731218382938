import React from "react";
import PropTypes from "prop-types";

const ServerFormErrors = (props) => {
  return (
    <div>
      {props.errorMessages?.length > 0 && (
        <div className="form-errors">
          <p>{props.customHeader || 'There were errors when saving:'}</p>
          <ul>
            {props.errorMessages.map((err, ind) => {
              return <li key={ind}>{err}</li>;
            })}
          </ul>
        </div>
      )}
    </div>
  );
};

ServerFormErrors.propTypes = {
  errorMessages: PropTypes.arrayOf(PropTypes.string),
  customHeader: PropTypes.string
};

export default ServerFormErrors;
