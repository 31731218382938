import React, { useState } from "react";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import ServerFormErrors from "../util/ServerFormErrors";
import ax from "../util/axios";
import { IntlProvider, useIntl } from "react-intl";
import { translations } from "../util/i18n";
import convertToFormData from "../util/upload";
import { add_submit_overlay, remove_submit_overlay } from "../util/submit_overlay";
import LoadingBar from '../charts/LoadingBar';
const ImportSimulationsForm = (props) => {
  const intl = useIntl();
  const [errorMessage, setErrorMessages] = useState();
  const [progress, setProgress] = useState(0);
  const [successCount, setSuccessCount] = useState(0);
  const [failedCount, setFailedCount] = useState(0);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    setErrorMessages([]);
    setSuccessCount(0);
    setFailedCount(0);
    if (data['file'].length > 5) {
      let allFiles = Array.from(data['file']);
      let filesCount = allFiles.length;
      let chunkProgress = 100 / filesCount;
      let chunkStep = 0;
      let errors = [];
      let successCounter = 0;
      let failedCounter = 0;
      let step = 0;
      const uploadNextChunk = async () => {
        let chunkFiles = null;
        if (allFiles.length !== 0) {
          if (allFiles.length < 5) {
            step = allFiles.length;
            chunkFiles = allFiles.splice(0, allFiles.length);
          } else {
            step = 5;
            chunkFiles = allFiles.splice(0, 5);
          }

          data['file'] = chunkFiles;
          const formData = convertToFormData(data, ["file"]);
          setProgress(Math.round((chunkStep + step) * chunkProgress));
          ax({
            method: "POST",
            url: props.url,
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
          })
            .then(function () {
              successCounter = successCounter + step;
              chunkStep = chunkStep + step;
              uploadNextChunk();
            })
            .catch((err) => {
              if (err.toString() == "Error: Network Error") {
                errors = errors.concat([intl.formatMessage({ id: "students.import-students.refresh-page" })]);
                setErrorMessages(errors);
                failedCounter = failedCounter + chunkFiles.length;
                setFailedCount(failedCounter);
                uploadNextChunk();
              } else {
                successCounter = successCounter + err.response.data.success_count;
                errors = errors.concat(JSON.parse(err.response.data.errors));
                setErrorMessages(errors);
                failedCounter = failedCounter + (chunkFiles.length - err.response.data.success_count);
                setFailedCount(failedCounter);
              }
              uploadNextChunk();
            });
        } else {
          setProgress(0);
          setSuccessCount(successCounter);
        }
      }
      uploadNextChunk();
    } else {
      const formData = convertToFormData(data, ["file"]);
      add_submit_overlay();
      ax({
        method: "POST",
        url: props.url,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          setSuccessCount(response.data.success_count);
        })
        .catch((err) => {
          if (err.toString() == "Error: Network Error") {
            setErrorMessages([intl.formatMessage({ id: "students.import-students.refresh-page" })]);
            setFailedCount(data['file'].length);
          } else {
            setSuccessCount(err.response.data.success_count);
            setErrorMessages(JSON.parse(err.response.data.errors));
            setFailedCount(data['file'].length - err.response.data.success_count);
          }
        });
      remove_submit_overlay();
    }
  };

  window.addEventListener("beforeunload", (event) => {
    if (progress !== 0) {
      event.preventDefault();
    }
  });

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} className="narrow">
        <div className="form-group">
          <label>{'Please select files(s) to import'}</label>
          <input
            type="file"
            accept={'text/plain,.json,application/json'}
            multiple={true}
            className="form-control"
            {...register("file", { required: true })}
          ></input>
          {errors.file?.type === "required" && (
            <div className="field-error">{intl.formatMessage({ id: "shared.forms.required" })}</div>
          )}
        </div>

        { progress === 0 &&
          <button type="submit" className="btn btn-primary small angled-top-left">
            {intl.formatMessage({ id: "students.import-students.import" })}
          </button>
        }
      </form>

      {progress !== 0 && <LoadingBar fill_percent={`${progress}%`} />}

      { progress === 0 && successCount !== 0 &&
        <div className="success-counter">{`${successCount} simulation(s) successfully imported!`}</div>
      }

      <ServerFormErrors errorMessages={errorMessage} customHeader={`${failedCount} simulation(s) weren't imported:`}></ServerFormErrors>
    </div>
  );
};

ImportSimulationsForm.propTypes = {
  url: PropTypes.string.isRequired,
  adminArea: PropTypes.bool,
};

export default ImportSimulationsForm;

export const WithIntlProvider = (props) => {
  return (
    <IntlProvider
      locale={window._FLAIM_LOCALE}
      key={window._FLAIM_LOCALE}
      messages={translations[window._FLAIM_LOCALE]}
    >
      <ImportSimulationsForm {...props} />
    </IntlProvider>
  );
};
