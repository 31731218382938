import React from "react";
import { Bar } from "react-chartjs-2";
import PropTypes from "prop-types";
import { IntlProvider, useIntl } from "react-intl";
import { translations } from "../util/i18n";
import { generateRandomColorArray } from '../util/color_genrator';

const AverageScoreByScenarioBarChart = (props) => {
  const intl = useIntl();

  const { gridColor, ticksColor, titleColor } = (() => {
    const theme = document.body.getAttribute('data-theme');
    return theme === 'dark'
            ? { gridColor: '#8a9097', ticksColor: '#e5e7ea', titleColor: '#e5e7ea' }
            : { gridColor: '#64686A', ticksColor: '#252728', titleColor: '#252728' };
  })();

  const avgScoreArray = (() => {
    let scoreArray = [];
    const barColors = generateRandomColorArray(props.labels.length);
    for(let i = 0; i < props.values.length; i++) {
      scoreArray.push({
        label: props.labels[i],
        data: [props.values[i]],
        fill: false,
        backgroundColor: barColors[i],
        borderColor: barColors[i],
        barPercentage: 0.8,
      });
    }
    return scoreArray;
  })();

  const data = {
    labels: ['Average Score (%)'],
    datasets: avgScoreArray,
  };

  const options = {
    aspectRatio: 2.5,
    responsive: true,
    scales: {
      y: {
        min: 0,
        max: 100,
        grid: {
          color: gridColor,
          borderColor: gridColor,
        },
        ticks: {
          color: ticksColor,
          font: {
            size: 13,
            weight: '700',
            family: "Adrianna",
          },
        },
        title: {
          display: true,
          text: intl.formatMessage({ id: "shared.chart.average-score" }),
          color: titleColor,
          font: {
            size: 13,
            weight: '700',
            family: "Adrianna",
          },
        },
      },
      x: {
        ticks: {
          display: false,
        },
        grid: {
          drawBorder: false,
          display: true,
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: 'top',
        labels: {
          boxWidth: 10,
          padding: 8,
          font: {
            size: 12
          }
        }
      },
    },
    datasets: {
      bar: {
        maxBarThickness: 40,
      },
    },
  };

  return <Bar data={data} options={options} />;
};

AverageScoreByScenarioBarChart.propTypes = {
  values: PropTypes.array,
  labels: PropTypes.array,
  brandColor: PropTypes.string,
};

export default AverageScoreByScenarioBarChart;

export const WithIntlProvider = (props) => {
  return (
    <IntlProvider
      locale={window._FLAIM_LOCALE}
      key={window._FLAIM_LOCALE}
      messages={translations[window._FLAIM_LOCALE]}
    >
      <AverageScoreByScenarioBarChart {...props} />
    </IntlProvider>
  );
};
