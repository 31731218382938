const getRandomColor = () => {
  const letters = '0123456789ABCDEF'.split('');
  let color = '#';
  for (let i = 0; i < 6; i++ ) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

const generateRandomColorArray = (length) => {
  return Array.apply(null, Array(length)).map(() => { return getRandomColor() });
};

export { generateRandomColorArray };
